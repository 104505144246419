<template>
  <div
    class="min-h-screen w-full overflow-hidden bg-amber-50 bg-gradient-to-r to-amber-100 dark:bg-slate-900 dark:from-slate-800 dark:text-slate-300"
  >
    <LayoutNavbar class="z-10" />
    <div class="mb-20 mt-12">
      <UContainer class="relative pt-5">
        <slot />
      </UContainer>
    </div>
  </div>
</template>
